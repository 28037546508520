import PrimaryFooter from './Primary'
import SecondaryFooter from './Secondary'
import {useRouter} from 'next/router'
import Hidden from 'src/components/helpers/Hidden'
import {useMediumSize} from 'src/hooks/screenSize'

export default function Footer() {
  const router = useRouter()
  const isConfirmAndPayPage = router.pathname === '/booking/[...uid]'
  const isMediumSize = useMediumSize()

  return (
    <Hidden when={isConfirmAndPayPage && isMediumSize}>
      <footer className="bg-black p-6 lg:py-3">
        <PrimaryFooter />
        <div className="w-full h-px bg-white my-2.5" />
        <SecondaryFooter />
      </footer>
    </Hidden>
  )
}
