import {useState} from 'react'
import {useTranslation} from 'next-i18next'
import {makeStyles} from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import AppIcon from 'src/components/elements/icons/AppIcon'
import AppLink from 'src/components/elements/Link'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppTypography from 'src/components/elements/typography/AppTypography'
import ChaletGroupMenu from 'src/components/modules/chaletGroupMenu/ChaletGroupMenu'
import {sections} from 'src/components/modules/chaletGroupMenu/data'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_EN} from 'src/constants/locale'

const links = [
  {
    label: 'gift_certificate',
    path_en: '/gift-card',
    path: '/certificat-cadeau',
  },
  {
    label: 'training_academy',
    path: 'https://academie.monsieurchalets.com/',
    path_en: 'https://academie.monsieurchalets.com/',
    new_tab: true,
  },
  {
    label: 'The agency',
    path_en: '/cottage-rental-agency',
    path: '/agence-location-de-chalets',
  },
]

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, palette, breakpoints}) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(0, 0, 4, 0),
    paddingBottom: 0,
    margin: 0,
    width: '100%',
    [breakpoints.down('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: spacing(3),
    },
  },
  linksWrapper: {
    display: 'flex',
    color: palette.common.white,
    gap: spacing(3),
    width: '100%',
    [breakpoints.down('md')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: spacing(2),
    },
  },
  primaryLink: {
    color: palette.common.white,
    fontSize: '18px !important',
  },
  secondaryLink: {
    color: palette.common.white,
    fontSize: '14px !important',
  },
  buttonLink: {
    color: palette.common.white,
    fontSize: '18px !important',
    display: 'flex',
    alignItems: 'center',
  },
  iconsContainer: {
    display: 'flex',
    gap: spacing(5),
  },
  icon: {
    height: spacing(5),
    width: spacing(5),
  },
  groupCabinsForRent: {
    display: 'none',
    [breakpoints.down(1130)]: {
      display: 'block',
    },
    width: '100%',
    backgroundColor: palette.common.black,
    marginTop: '0 !important',
    '&:before': {
      backgroundColor: palette.common.black,
    },
    '& .MuiAccordionSummary-content': {
      margin: spacing(0, 0),
      '& p': {
        color: palette.common.white,
      },
    },
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      padding: spacing(0, 0),
      minHeight: spacing(2),
    },
    '& .MuiAccordionDetails-root': {
      padding: spacing(0, 0),
    },
  },
}))

export default function PrimaryFooter() {
  const classes = useStyles()
  const {t} = useTranslation(['footer', 'common'])
  const currentLocale = useCurrentLocale()

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorElement(e.currentTarget)
  const handleCloseMenu = () => setAnchorElement(null)

  return (
    <div className={classes.container}>
      <div className={classes.linksWrapper}>
        {links.map(({path, path_en, label, new_tab}, index) => (
          <AppLink
            key={index}
            href={currentLocale === LOCALE_EN ? path_en : path}
            className={classes.primaryLink}
            color="inherit"
            variant="body2"
            newTab={new_tab}
          >
            {t(label, {ns: 'common'})}
          </AppLink>
        ))}
        <div className="hidden lg:block">
          <AppButton
            onClick={handleOpenMenu}
            className={classes.buttonLink}
            endIcon={<AppIcon name="chevron-up" />}
            style={{lineHeight: 'normal', padding: 0}}
          >
            {t('group_cabins_for_rent', {ns: 'common'})}
          </AppButton>
        </div>
        <GroupCabinsForRent />
      </div>
      <ChaletGroupMenu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
      />
      <div className={classes.iconsContainer}>
        <a
          href="https://www.facebook.com/monsieurchalets"
          target="_blank"
          rel="noreferrer"
        >
          <AppIcon name="fb" className={classes.icon} />
        </a>
        <a
          href="https://www.pinterest.ca/monsieurchalets/"
          target="_blank"
          rel="noreferrer"
        >
          <AppIcon name="pi" className={classes.icon} />
        </a>
        <a
          href="https://www.instagram.com/monsieurchalets/"
          target="_blank"
          rel="noreferrer"
        >
          <AppIcon name="in" className={classes.icon} />
        </a>
      </div>
    </div>
  )
}

function GroupCabinsForRent() {
  const [expanded, setExpanded] = useState(false)

  const {t} = useTranslation(['footer', 'common'])
  const classes = useStyles()
  const currentLocale = useCurrentLocale()

  const onChange = (_: object, expanded: boolean) => setExpanded(expanded)

  return (
    <Accordion className={classes.groupCabinsForRent} onChange={onChange}>
      <AccordionSummary>
        <div className="inline-flex flex-row justify-between items-center">
          <AppTypography variant="displaySmall">
            {t('group_cabins_for_rent', {ns: 'common'})}
          </AppTypography>
          {expanded ? (
            <ExpandMoreIcon style={{color: 'white'}} />
          ) : (
            <ArrowRightIcon style={{color: 'white'}} />
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col pl-4">
          {sections.map(({links}) => {
            return links.map(({label, label_en, path}, index: number) => (
              <AppLink
                key={index}
                href={path}
                variant="body2"
                className={classes.secondaryLink}
              >
                {currentLocale === LOCALE_EN ? label_en : label}
              </AppLink>
            ))
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
