import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Menu, {MenuProps} from '@material-ui/core/Menu'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import AppLink from 'src/components/elements/Link'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_EN} from 'src/constants/locale'
import {sections} from './data'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({breakpoints, spacing, shadows, palette}) => ({
    gridContainer: {
      margin: 0,
      width: '100%',
      maxHeight: '100%',
    },
    paper: {
      marginTop: spacing(2),
      background: palette.common.black,
      '& .MuiPopover-paper': {
        boxShadow: shadows[2],
      },
      [breakpoints.down('md')]: {
        maxHeight: '100% !important',
      },
      [breakpoints.between('xs', 'sm')]: {
        marginRight: spacing(2.5),
      },
      [breakpoints.up(800)]: {
        transform: 'translate(-10%, -30%) !important',
        width: '700px !important',
        transformOrigin: 'unset !important',
      },
    },
    list: {
      padding: 0,
    },
    closeIcon: {
      display: 'none',
      position: 'absolute',
      right: spacing(2),
      top: spacing(2),
      fontSize: 16,
      [breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    text: {
      color: palette.common.white,
      marginBottom: spacing(0.75),
    },
  }),
  {index: 1},
)

export default function DiscoverPlacesMenu(
  props: {onClose: () => void} & MenuProps,
) {
  const {onClose} = props

  const classes = useStyles()

  return (
    <>
      <Content isSeo />
      <Menu
        classes={{
          paper: classes.paper,
          list: classes.list,
        }}
        {...props}
      >
        <Grid className={classes.gridContainer} container spacing={4}>
          <Content />
        </Grid>
        <Close className={classes.closeIcon} onClick={onClose} />
      </Menu>
    </>
  )
}

function Content(props: {isSeo?: boolean}) {
  const classes = useStyles()
  const currentLocale = useCurrentLocale()

  const hiddenStyle = props.isSeo ? {display: 'none'} : {}

  return (
    <Grid
      className={classes.gridContainer}
      style={hiddenStyle}
      container
      spacing={4}
    >
      {sections.map(({title, links}, index) => (
        <Grid item xs={12} sm={6} lg={4} key={index}>
          <Typography color="primary" variant="subtitle2">
            {title.toUpperCase()}
          </Typography>
          {links.map(({label, path, label_en, path_en}) => (
            <AppLink
              key={label}
              href={currentLocale === LOCALE_EN ? path_en : path}
              variant="body2"
              className={classes.text}
            >
              {currentLocale === LOCALE_EN ? label_en : label}
            </AppLink>
          ))}
        </Grid>
      ))}
    </Grid>
  )
}
