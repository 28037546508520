export const sections = [
  {
    title: '',
    links: [
      {
        label: 'Chalets à louer 1 personne',
        path: '/chalets-a-louer-1-personne',
        label_en: 'Cottages for rent 1 person',
        path_en: '/cottages-for-rent-1-person',
      },
      {
        label: 'Chalets à louer 2 personnes',
        path: '/chalets-a-louer-2-personnes',
        label_en: 'Cottages for rent 2 persons',
        path_en: '/cottages-for-rent-2-people',
      },
      {
        label: 'Chalets à louer 3 personnes',
        path: '/chalets-a-louer-3-personnes',
        label_en: 'Cottages for rent 3 persons',
        path_en: '/cottages-for-rent-3-people',
      },
      {
        label: 'Chalets à louer 4 personnes',
        path: '/chalets-a-louer-4-personnes',
        label_en: 'Cottages for rent 4 persons',
        path_en: '/cottages-for-rent-4-people',
      },
      {
        label: 'Chalets à louer 5 personnes',
        path: '/chalets-a-louer-5-personnes',
        label_en: 'Cottages for rent 5 persons',
        path_en: '/cottages-for-rent-5-people',
      },
      {
        label: 'Chalets à louer 6 personnes',
        path: '/chalets-a-louer-6-personnes',
        label_en: 'Cottages for rent 6 persons',
        path_en: '/cottages-for-rent-6-people',
      },
      {
        label: 'Chalets à louer 7 personnes',
        path: '/chalets-a-louer-7-personnes',
        label_en: 'Cottages for rent 7 persons',
        path_en: '/cottages-for-rent-7-people',
      },
      {
        label: 'Chalets à louer 8 personnes',
        path: '/chalets-a-louer-8-personnes',
        label_en: 'Cottages for rent 8 persons',
        path_en: '/cottages-for-rent-8-people',
      },
      {
        label: 'Chalets à louer 9 personnes',
        path: '/chalets-a-louer-9-personnes',
        label_en: 'Cottages for rent 9 persons',
        path_en: '/cottages-for-rent-9-people',
      },
      {
        label: 'Chalets à louer 10 personnes',
        path: '/chalets-a-louer-10-personnes',
        label_en: 'Cottages for rent 10 persons',
        path_en: '/cottages-for-rent-10-people',
      },
      {
        label: 'Chalets à louer 11 personnes',
        path: '/chalets-a-louer-11-personnes',
        label_en: 'Cottages for rent 11 persons',
        path_en: '/cottages-for-rent-11-people',
      },
      {
        label: 'Chalets à louer 12 personnes',
        path: '/chalets-a-louer-12-personnes',
        label_en: 'Cottages for rent 12 persons',
        path_en: '/cottages-for-rent-12-people',
      },
    ],
  },
  {
    title: '',
    links: [
      {
        label: 'Chalets à louer 13 personnes',
        path: '/chalets-a-louer-13-personnes',
        label_en: 'Cottages for rent 13 persons',
        path_en: '/cottages-for-rent-13-people',
      },
      {
        label: 'Chalets à louer 14 personnes',
        path: '/chalets-a-louer-14-personnes',
        label_en: 'Cottages for rent 14 persons',
        path_en: '/cottages-for-rent-14-people',
      },
      {
        label: 'Chalets à louer 15 personnes',
        path: '/chalets-a-louer-15-personnes',
        label_en: 'Cottages for rent 15 persons',
        path_en: '/cottages-for-rent-15-people',
      },
      {
        label: 'Chalets à louer 16 personnes',
        path: '/chalets-a-louer-16-personnes',
        label_en: 'Cottages for rent 16 persons',
        path_en: '/cottages-for-rent-16-people',
      },
      {
        label: 'Chalets à louer 17 personnes',
        path: '/chalets-a-louer-17-personnes',
        label_en: 'Cottages for rent 17 persons',
        path_en: '/cottages-for-rent-17-people',
      },
      {
        label: 'Chalets à louer 18 personnes',
        path: '/chalets-a-louer-18-personnes',
        label_en: 'Cottages for rent 18 persons',
        path_en: '/cottages-for-rent-18-people',
      },
      {
        label: 'Chalets à louer 19 personnes',
        path: '/chalets-a-louer-19-personnes',
        label_en: 'Cottages for rent 19 persons',
        path_en: '/cottages-for-rent-19-people',
      },
      {
        label: 'Chalets à louer 20 personnes',
        path: '/chalets-a-louer-20-personnes',
        label_en: 'Cottages for rent 20 persons',
        path_en: '/cottages-for-rent-20-people',
      },
      {
        label: 'Chalets à louer 25 personnes',
        path: '/chalets-a-louer-25-personnes',
        label_en: 'Cottages for rent 25 persons',
        path_en: '/cottages-for-rent-25-people',
      },
      {
        label: 'Chalets à louer 30 personnes',
        path: '/chalets-a-louer-30-personnes',
        label_en: 'Cottages for rent 30 persons',
        path_en: '/cottages-for-rent-30-people',
      },
      {
        label: 'Chalets à louer 40 personnes',
        path: '/chalets-a-louer-40-personnes',
        label_en: 'Cottages for rent 40 persons',
        path_en: '/cottages-for-rent-40-people',
      },
      {
        label: 'Chalets à louer 50 personnes',
        path: '/chalets-a-louer-50-personnes',
        label_en: 'Cottages for rent 50 persons',
        path_en: '/cottages-for-rent-50-people',
      },
    ],
  },
  {
    title: ' ',
    links: [
      {
        label: 'Chalets à louer 1 chambre',
        path: '/chalets-a-louer-1-chambre',
        label_en: 'Cottages to rent 1 bedroom',
        path_en: '/cottages-for-rent-1-bedroom',
      },
      {
        label: 'Chalets à louer 2 chambres',
        path: '/chalets-a-louer-2-chambres',
        label_en: 'Cottages to rent 2 bedrooms',
        path_en: '/cottages-for-rent-2-bedrooms',
      },
      {
        label: 'Chalets à louer 3 chambres',
        path: '/chalets-a-louer-3-chambres',
        label_en: 'Cottages to rent 3 bedrooms',
        path_en: '/cottages-for-rent-3-bedrooms',
      },
      {
        label: 'Chalets à louer 4 chambres',
        path: '/chalets-a-louer-4-chambres',
        label_en: 'Cottages to rent 4 bedrooms',
        path_en: '/cottages-for-rent-4-bedrooms',
      },
      {
        label: 'Chalets à louer 5 chambres',
        path: '/chalets-a-louer-5-chambres',
        label_en: 'Cottages to rent 5 bedrooms',
        path_en: '/cottages-for-rent-5-bedrooms',
      },
      {
        label: 'Chalets à louer 6 chambres',
        path: '/chalets-a-louer-6-chambres',
        label_en: 'Cottages to rent 6 bedrooms',
        path_en: '/cottages-for-rent-6-bedrooms',
      },
      {
        label: 'Chalets à louer 7 chambres',
        path: '/chalets-a-louer-7-chambres',
        label_en: 'Cottages to rent 7 bedrooms',
        path_en: '/cottages-for-rent-7-bedrooms',
      },
      {
        label: 'Chalets à louer 8 chambres',
        path: '/chalets-a-louer-8-chambres',
        label_en: 'Cottages to rent 8 bedrooms',
        path_en: '/cottages-for-rent-8-bedrooms',
      },
      {
        label: 'Chalets à louer 9 chambres',
        path: '/chalets-a-louer-9-chambres',
        label_en: 'Cottages to rent 9 bedrooms',
        path_en: '/cottages-for-rent-9-bedrooms',
      },
      {
        label: 'Chalets à louer 10 chambres',
        path: '/chalets-a-louer-10-chambres',
        label_en: 'Cottages to rent 10 bedrooms',
        path_en: '/cottages-for-rent-10-bedrooms',
      },
      {
        label: 'Chalets à louer 11 chambres',
        path: '/chalets-a-louer-11-chambres',
        label_en: 'Cottages to rent 11 bedrooms',
        path_en: '/cottages-for-rent-11-bedrooms',
      },
    ],
  },
]
